:root {
    --sidebar-width: 250px;
}

* {
    font-family: "Nunito", sans-serif;
}

body {
    height: 100vh;
}

.login-background {
    background-image: url(../../public/images/background.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-outline-default {
    outline: 2px solid hsl(83, 56%, 49%);
    font-weight: 700;
    color: rgb(100, 100, 100);

    &:hover {
        background-color: hsl(83, 56%, 49%);
        color: white;
        font-weight: 700;
    }

    &:active {
        background-color: hsl(83, 56%, 49%) !important;
        color: white !important;
    }

    &:focus {
        background-color: none !important;
        outline: 2px solid hsl(83, 56%, 49%) !important;
        color: rgb(100, 100, 100) !important;
    }
}


.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
    box-sizing: border-box;
    color: rgb(50, 50, 50);

    .sidebar {
        position: absolute;
        background-color: hsl(83, 56%, 49%);
        background: linear-gradient(to right, hsl(83, 56%, 45%), hsl(83, 56%, 49%));
        width: var(--sidebar-width);
        height: 100vh;
        z-index: 9999;
        box-shadow: 2px 0px 3px rgba(0,0,0,0.4);
        

        .sidebar-header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 4em;
            border-bottom: 1px solid hsl(83, 56%, 60%);

            img {
                width: 70%;
            }
        }

        .menu {
            padding: 15px;
            overflow: auto;
            height: calc(100vh - 4em);

            ul {
                list-style-type: none;
                margin: 0px;
                padding: 0px;

                li {
                    margin-bottom: 15px;
                    .nav-link {
                        color: white;
                        font-weight: bold;
                        padding: 10px 15px;
                        border-radius: 5px;

                        &:hover {
                            background-color: rgba(255,255,255,0.2);
                        }
                        
                        &.active {
                            background-color: rgba(255,255,255,0.4);
                            color: rgb(100, 100, 100);
                        }
                    }

                }
            }
        }
    }
    
    .main-panel {
        float: right;
        width: calc(100% - var(--sidebar-width));
        overflow: hidden;
        min-height: 100vh;
        max-height: 100vh;

        .navbar {
            border-bottom: 1px solid rgb(220,220,220);
            height: 4em;
            padding: 0px 15px;
            display: flex;
            justify-content: space-between;
        }

        .content {
            padding: 15px;
            height: calc(100vh - 4em);
            overflow: auto;

            .panel {
                background-color: white;
                border: 1px solid rgb(220, 220, 220);
                padding: 15px;
                border-radius: 10px;
                box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
                overflow: auto;

                h1 {
                    background-color: hsl(83, 56%, 49%);
                    text-align: center;
                    color: white;
                    font-size: 1.25em;
                    padding: 10px;
                    border-radius: 5px;
                }

                .toolbar {
                    margin-bottom: 15px;
                    background-color: hsl(83, 56%, 95%);
                    padding: 10px;
                    border-radius: 5px;
                }

                .links-panel {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .card {
                        margin: 15px 0px;
                        box-shadow: 1px 1px 3px rgb(220, 220, 220);
                        .card-body {
                            padding: 0px;
                            a {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

}

.km-table {
    width: 100%;
    border: 1px solid hsl(83, 56%, 49%);
    border-collapse: collapse;

    th {
        background-color: hsl(83, 56%, 49%);
        padding: 10px;
        color: white;
        text-align: center;
    }
    td {
        padding: 2px 5px;

        &:not(:first-child) {
            border-left: 1px solid hsl(83, 56%, 49%)
        }

        &.center {
            text-align: center;
        }
    }

    &.km-table-striped {
        tbody {
            tr:nth-child(2n) {
                background-color: hsl(83, 56%, 85%);
            }
        }
    }

}


.roster {
    border: 1px solid rgb(200, 200, 200);
    border-collapse: collapse;
    border-radius: 5px;
    font-size: 13px;
    width: 100%;

    td, th {
        border: 1px solid rgb(200, 200, 200);
        text-align: center;
    }

    td {
        padding: 2px;
    }

    th {
        text-align: center;
        background-color: hsl(83, 56%, 75%);
        padding: 5px 2px;
    }

    tr:nth-child(2n) {
        background-color: rgb(240, 240, 240);
    }
}